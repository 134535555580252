/* eslint-env browser */
import './polyfills';
import './confirm';
import './confirm-redemption';
import './confirm-costcode-rejection';
import './confirm-costcode-approval';
import './qrcode-scanner';
import './modal-alert';
import './card-reader';

import $ from 'jquery';
import * as flexiPicker from './flexi-picker';

/**
 * Attach handlers to all elements inside $scope. All jQuery selects
 * must be scoped to $scope, and you should only call bindTo on content
 * that you know has not had handlers attached already (either a whole page
 * that's just been loaded, or a piece of HTML you've just loaded into the
 * document dynamically)
 */
function bindTo($scope) {
  $('[data-toggle="popover"]', $scope).popover();

  flexiPicker.bindTo($scope);
}

$(() => {
  const $html = $('html');

  // Apply to all content loaded non-AJAXically
  bindTo($('#main'));

  // Any selectors below should only be for things that we know won't be inserted into the
  // page after DOM ready.

  // Dismiss popovers when clicking away
  function closePopover($popover) {
    const $creator = $popover.data('creator');
    if ($creator) {
      $creator.popover('hide');
    }
  }

  $('.autotab').keyup((e) => {
    if (e.target.value.length === e.target.maxLength) {
      const tabs = $(e.target).closest('.codeContainer').find('.autotab');
      const indx = tabs.index(e.target);
      if (indx !== tabs.length - 1) {
        tabs[indx + 1].focus();
      }
    }
  });

  $('.select-all-checkbox-parent').click((e) => {
    $('.select-all-checkbox-child').prop('checked', e.target.checked);
  });

  $html
    .on('shown.bs.popover', (e) => {
      const $po = $(e.target).popover().data('bs.popover').tip();
      $po.data('creator', $(e.target));
    })
    .on('click.popoverDismiss', (e) => {
      const $target = $(e.target);

      // if clicking anywhere other than the popover itself
      if ($target.closest('.popover').length === 0 && $(e.target).closest('.has-popover').length === 0) {
        $('.popover').each((i, popover) => closePopover($(popover)));
      } else if ($target.closest('.close').length > 0) {
        closePopover($target.closest('.popover'));
      }
    })
    .on('keyup.popoverDismiss', (e) => {
      const key = e.which || e.keyCode;
      if (key === 27) {
        $('.popover').each((i, popover) => closePopover($(popover)));
      }
    });


  if ($('#orderVoucherTermsModal').length) {
    $('#orderVoucherTermsModal').modal('show');

    $('.order-voucher-terms').on('click', (event) => {
      event.preventDefault();
      $('#orderVoucherTermsModal').modal('show');
    });

    $('.closeButton').on('click', () => {
      $('#orderVoucherTermsModal').modal('hide');
    });
  }
});
